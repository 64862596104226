<template>
  <b-container fluid>
    <h3 class="mt-3 mb-2">Dados do {{ type }}</h3>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <label for="name">Nome</label>
          <b-input id="name" v-model="form.name" placeholder="Descrever" />
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group>
          <label for="cpf">CPF</label>
          <b-input
            id="cpf"
            v-model="form.cpf"
            placeholder="Descrever"
            v-mask="'###.###.###-##'"
            @change="validateCpf"
          />
          <div v-if="!validCpf" class="custom-invalid-feedback">
            CPF inválido
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group>
          <label for="rg">RG</label>
          <b-input id="rg" v-model="form.rg" placeholder="Descrever" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { cpf } from 'cpf-cnpj-validator'
import debounce from 'lodash/debounce'
export default {
  name: 'GeneralData',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      validator: value => ['acompanhante', 'paciente'].includes(value)
    }
  },
  data: () => ({
    isInvalid: false,
    validCpf: true
  }),
  created() {
    this.validateCpf = debounce(this.validateCpf, 2000)
  },
  methods: {
    validateCpf() {
      this.validCpf = cpf.isValid(this.form.cpf)
    }
  }
}
</script>
